import { ApolloProvider } from '@apollo/client';
import { GQLClient } from '@oolio-group/gql-client';
import React, { useState, useEffect } from 'react';
import { StatusBar } from 'react-native';
import { authFlowLink } from './utils/authFlowLink';
import config from './config';
import AppNavigator from './navigator/AppNavigator';
import * as settings from './state/preferences';
import { getApolloCache } from '@oolio-group/client-utils';

import { SessionProvider } from './hooks/useSession';
import { ScaleProvider } from './hooks/useScale';
import {
  Locale,
  LocalizationProvider,
  supportedLocales,
} from '@oolio-group/localization';
import Loader from './component/Loader/Loader';
import { getTracerLink } from '@oolio-group/tracer-client';
import { ThemeProvider } from './hooks/useColors';
import { App as AppType } from '@oolio-group/domain';
import AnalyticsInitilizer from './analytics/AnalyticsInitilizer';
import BackgroundWorker from './workers/BackgroundWorker';
import { workerInstanceVar } from './state/apolloVars';

// TODO: Add localization in KDS
const App = () => {
  const [locale, setLocale] = useState<Locale>();
  const [client] = useState(() => {
    const cache = getApolloCache();

    const getSession = async () => {
      const { kitchenDisplay, currentStore, token } =
        (await settings.getSession()) as settings.Session;
      return {
        device: kitchenDisplay,
        currentStore: currentStore,
        token: token,
        activeApp: AppType.KITCHEN_DISPLAY_APP,
      };
    };

    return new GQLClient(
      {
        cache,
        middlewares: authFlowLink.concat(
          getTracerLink({
            uri: config.SERVICE_URI,
          }),
        ),
        service: config.SERVICE_URI,
        getSession,
        client: 'kds-app',
        version: '1.0',
      },
      true,
    ).getApolloClient();
  });

  useEffect(() => {
    (async () => {
      const session = await settings.getSession();
      setLocale(supportedLocales[session?.localeCode || config.DEFAULT_LOCALE]);
    })();
  }, []);

  useEffect(() => {
    const worker = new BackgroundWorker();
    if (worker.worker) workerInstanceVar(worker);
    return () => {
      if (worker.worker) worker.worker.terminate();
    };
  }, []);

  return (
    <>
      <StatusBar animated={true} barStyle="light-content" hidden />
      {client && locale ? (
        <ApolloProvider client={client}>
          <SessionProvider>
            <AnalyticsInitilizer>
              <LocalizationProvider
                locale={locale}
                country={config.DEFAULT_COUNTRY_CODE}
              >
                <ThemeProvider>
                  <ScaleProvider>
                    <AppNavigator />
                  </ScaleProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </AnalyticsInitilizer>
          </SessionProvider>
        </ApolloProvider>
      ) : (
        <Loader show={true} />
      )}
    </>
  );
};
export default App;
