import React, { useState, useEffect, useCallback } from 'react';
import { View, Text } from 'react-native';
import { Docket, DocketStatus } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useScale } from '../../../hooks/useScale';
import { useDockets } from '../../../hooks/useDockets';
import { useReactiveVar } from '@apollo/client/react/hooks';
import {
  docketsUpdated,
  openDocketslastUpdatedAt,
  allDayViewItemsVar,
} from '../../../state/apolloVars';
import {
  getPaginatedDocketItems,
  PaginatedDocketItems,
} from '../../../utils/layoutHelper';
import { headers } from '../../../utils/fetchHeaders';
import { transformDocketToDocketDisplayItems } from '../../../utils/docketHelper/transformDockets';
import { DOCKET_WIDTH, FEATURES } from '../../../common/constants';
import { DocketDisplayItem } from '../../../common/types';
import MainScreenStyles from '../MainScreen.styles';
import DocketsGrid from './DocketsGrid';
import DocketHermes from './DocketHermes';
import Pagination from '../../../component/Pagination/Pagination';
import { usePrinterProfiles } from '../../../hooks/usePrinterProfiles';
import { useOrderTypes } from '../../../hooks/useOrderTypes';
import { useSalesChannels } from '../../../hooks/useSalesChannels';
import { useSession } from '../../../hooks/useSession';
import { groupDocketItems } from '../../../utils/docketHelper/groupDocketItems';
import {
  getItemCounts,
  sortDockets,
} from '../../../utils/docketHelper/sortDockets';
import { docketsCategoriesCount } from '../../../state/apolloVars';
import { analyticsService } from '../../../analytics/AnalyticsService';

export interface DocketsLayoutProps {
  docketStatus: DocketStatus | string;
  headers: headers;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  enablePrint?: boolean;
}

const DocketsLayout: React.FC<DocketsLayoutProps> = ({
  docketStatus,
  headers,
  setCurrentPage: setCurrentPageIndex,
  currentPage,
  enablePrint,
}) => {
  const { scale } = useScale();
  const { session } = useSession();
  const styles = MainScreenStyles();
  const { translate } = useTranslation();
  const { getDockets, ackDocketsReceived } = useDockets();
  const docketsUpdatedVar = useReactiveVar(openDocketslastUpdatedAt);
  const [dockets, setDockets] = useState<Docket[]>([]);
  const [docketsToShow, setDocketsToShow] = useState<DocketDisplayItem[]>([]);
  const [paginatedDocketItems, setPaginatedDocketItems] = useState<
    PaginatedDocketItems[]
  >([]);
  const { printerProfiles } = usePrinterProfiles();
  const { orderTypes } = useOrderTypes();
  const { salesChannels } = useSalesChannels();
  const [columnHeight, setColumnHeight] = useState<number>(0);
  const [docketsReceivedAckEnabled, setDocketsReceivedAckEnabled] =
    useState(false);

  useEffect(() => {
    (async () => {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.DOCKETS_RECEIVED_ACK,
      );
      setDocketsReceivedAckEnabled(isEnabled);
    })();
  }, []);

  const getOrderTypeCode = useCallback(
    (id: string) => {
      return orderTypes.find(orderType => orderType?.id === id)?.code as string;
    },
    [orderTypes],
  );
  useEffect(() => {
    (async () => {
      if (!isNaN(docketsUpdatedVar)) {
        const dockets = await getDockets(docketStatus as DocketStatus);
        const filteredDockets = dockets.filter(x => {
          const validOrderTypes = printerProfiles.find(
            profile => profile.id === x?.printerProfileId,
          )?.orderTypes;

          if (!validOrderTypes || validOrderTypes?.length === 0) return x;
          if (validOrderTypes.includes(getOrderTypeCode(x?.orderType?.id)))
            return x;
          return;
        });
        if (docketStatus !== DocketStatus.COMPLETED) {
          const allDayViewData = groupDocketItems(filteredDockets);
          allDayViewItemsVar(allDayViewData);
        }

        const sortedDockets = sortDockets(
          session.sortingPref?.value || '',
          filteredDockets,
          docketStatus,
          orderTypes,
          salesChannels,
        );
        const itemCounts = getItemCounts(sortedDockets);
        if (itemCounts) docketsCategoriesCount(itemCounts);
        setDockets(sortedDockets);
        docketsReceivedAckEnabled && ackDocketsReceived(sortedDockets);
      }
    })();
  }, [
    getDockets,
    docketsUpdatedVar,
    docketStatus,
    printerProfiles,
    getOrderTypeCode,
    session.sortingPref,
    orderTypes,
    salesChannels,
    ackDocketsReceived,
    docketsReceivedAckEnabled,
  ]);

  useEffect(() => {
    if (dockets?.length) {
      const dataToShowtransformedDockets = dockets.map(
        transformDocketToDocketDisplayItems,
      );
      const dataToShow = dataToShowtransformedDockets?.flat();
      setDocketsToShow(dataToShow);
    } else {
      setDocketsToShow([]);
    }
  }, [dockets]);

  useEffect(() => {
    if (docketsToShow.length) {
      setPaginatedDocketItems(() =>
        getPaginatedDocketItems(
          docketsToShow,
          scale(DOCKET_WIDTH),
          scale,
          columnHeight,
        ),
      );
    } else {
      setPaginatedDocketItems([]);
    }
  }, [docketsToShow, scale, columnHeight]);

  useEffect(() => {
    docketsUpdated({ timestamp: Date.now() });
  }, []);

  const onChangePage = useCallback(
    (page: number) => {
      setCurrentPageIndex(page);
    },
    [setCurrentPageIndex],
  );

  return (
    <View style={styles.layout}>
      {paginatedDocketItems.length > 0 ? (
        <>
          <DocketsGrid
            status={docketStatus as DocketStatus}
            displayDocketItems={paginatedDocketItems}
            currentPage={currentPage}
            enablePrint={enablePrint}
            setColumnsHeight={setColumnHeight}
            columnsHeight={columnHeight}
          />
          <Pagination
            data={paginatedDocketItems.map(x => x.page)}
            currentPageIndex={currentPage}
            onChange={onChangePage}
          />
        </>
      ) : (
        <View style={styles.emptyView}>
          {docketStatus === DocketStatus.CREATED ? (
            <></>
          ) : (
            <Text style={styles.emptyText}>
              {translate('kitchenDisplay.emptyLayout')}
            </Text>
          )}
        </View>
      )}
      <DocketHermes
        headers={headers}
        docketStatus={docketStatus as DocketStatus}
      />
    </View>
  );
};

export default DocketsLayout;
